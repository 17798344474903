.ui.sortable.table thead th.sorted,
.ui.sortable.table thead th.sorted:hover,
.ui.sortable.table thead th:hover {
  background: #f2f2f2;
}

/* .react-datepicker {
  display: flex;
}
.react-datepicker__input-container input {
  outline: none;
  width: 100%;
} */
pre {
  font-family: inherit;
  white-space: break-spaces;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.map-tile {
  width: 50px;
  height: 50px;
  border: 1px solid #0000000a;
  display: inline-block;
}
.map-tile:hover {
  border: 1px solid rgb(54, 7, 226);
}
.hover-border {
  border: 1px solid #eee;
}
.hover-border:hover {
  border: 1px solid rgb(54, 7, 226);
}
.hover-border.active {
  border: 2px solid red;
}

.img {
  max-width: 300% !important;
}

body > canvas {
  width: 0px;
  height: 0px;
}